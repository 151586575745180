<template>
  <div class="flex flex-col gap-2">
    <h6 class="font-medium text-sm text-zinc-600 dark:text-zinc-400">
      {{ placeholder }}
    </h6>
    <div
      class="border-2 bg-zinc-100 dark:bg-zinc-900 dark:border-zinc-700 w-full box-border flex"
    >
      <input
        :required="isRequired"
        v-if="type !== 'textarea'"
        :type="type"
        :placeholder="placeholder"
        :value="modelValue"
        @input="updateValue"
        class="p-3 w-full text-black dark:text-white bg-zinc-100 placeholder:text-zinc-400 dark:placeholder:text-zinc-500 dark:bg-zinc-900 outline-none"
      />
      <textarea
        :required="isRequired"
        v-if="type === 'textarea'"
        rows="5"
        :placeholder="placeholder"
        :value="modelValue"
        @input="updateValue"
        class="p-3 w-full text-black dark:text-white bg-zinc-100 placeholder:text-zinc-400 dark:placeholder:text-zinc-500 dark:bg-zinc-900 outline-none"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: false,
      default: "text",
    },
    placeholder: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
