<template>
  <!-- Portfolio box -->
  <a :id="id" v-bind="$attrs" class="relative group cursor-default">
    <div class="w-full max-sm:max-w-[300px] max-sm:mx-auto h-full">
      <!-- Image -->
      <div class="relative w-full pb-[100%] overflow-hidden bg-jestaBlue">
        <img
          :src="pictureUrl"
          alt="portfolio image"
          loading="lazy"
          class="w-full h-full absolute top-0 left-0 object-center object-cover transition-all duration-1000 group-hover:scale-[1.05] opacity-[0.85] hover:opacity-100"
          :class="isFormerTransaction ? 'grayscale' : 'grayscale-0'"
        />
        <!-- Overlay -->
        <div
          class="absolute transition-all duration-1000 top-0 left-0 w-full h-full origin-top-left"
          :class="
            isFormerTransaction
              ? 'bg-[rgb(0_12_44/50%)] group-hover:bg-[rgb(0_12_44/70%)] max-md:group-hover:bg-[rgb(0_12_44/60%)]'
              : 'bg-black/10 group-hover:bg-black/40 max-md:bg-black/30'
          "
        ></div>

        <div
          class="absolute top-3 left-1 flex gap-1 [writing-mode:vertical-lr;] rotate-180 divide-white font-medium text-white text-base uppercase"
        >
          <!-- City -->
          <span>{{ city }}</span>
          <span>|</span>
          <!-- Country -->
          <span>{{ country }}</span>
          <span>|</span>
          <!-- Number of portfolio box -->
          <span>{{ number < 10 ? `0${number}` : number }}</span>
        </div>

        <div
          class="flex justify-center group-hover:opacity-100 opacity-0 max-md:opacity-100 transition-all duration-1000 items-center gap-3 absolute top-0 right-0 w-full h-full"
        >
          <button
            class="text-white border rounded-full p-3 text-xl"
            role="button"
            @click="() => this.$emit('removeProjectClick', id)"
          >
            <Icon icon="tabler:trash-filled" />
          </button>
          <router-link
            class="text-white border rounded-full p-3 text-xl"
            :to="`/portfolio/edit/${id}`"
          >
            <Icon icon="ri:ball-pen-fill" />
          </router-link>
        </div>
      </div>

      <div class="px-3 pt-3 flex flex-col gap-1">
        <!-- Title -->
        <p
          class="uppercase text-xl leading-[1.5rem] line-clamp-2 tracking-[0px] font-bold text-jestaDarkBlue dark:text-jestaBrown"
        >
          {{ title }}
        </p>

        <div
          class="font-medium flex flex-wrap text-zinc-500 dark:text-zinc-400"
        >
          <!-- Type -->
          <span
            v-for="(item, index) in types"
            :key="index"
            class="font-normal text-base tracking-[0px]"
            :class="[
              index !== types.length - 1
                ? 'pr-1 border-r border-zinc-500 dark:text-zinc-400 mr-1'
                : '',
            ]"
            >{{ capitalizeFirstLetter(item) }}</span
          >
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import { Icon } from "@iconify/vue";
export default {
  components: {
    Icon,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    number: {
      type: Number,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    pictureUrl: {
      type: String,
      required: true,
    },
    types: {
      type: Array,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
    city: {
      type: String,
      required: true,
    },
    isFormerTransaction: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      const word = string;
      const firstLetter = word.charAt(0).toUpperCase();
      const remainingLetters = word.slice(1);
      const capitalizeWord = firstLetter + remainingLetters;
      return capitalizeWord;
    },
  },
  mounted() {},
};
</script>
