<template>
  <div>
    <!-- Portfolio -->
    <div class="present-project h-auto p-3 pb-10">
      <nav class="flex flex-col" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <router-link
              to="/"
              class="inline-flex items-center text-sm font-medium text-zinc-700 hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-white"
            >
              <svg
                class="mr-2 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                ></path>
              </svg>
              Home
            </router-link>
          </li>
          <li>
            <div class="flex items-center">
              <svg
                class="w-6 h-6 text-zinc-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <router-link
                to="/portfolio/contact"
                class="ml-1 text-sm font-medium text-zinc-700 hover:text-zinc-900 md:ml-2 dark:text-zinc-400 dark:hover:text-white"
                >Contact</router-link
              >
            </div>
          </li>
        </ol>

        <div class="mt-5 w-full">
          <h1 class="text-2xl text-zinc-900 font-medium dark:text-zinc-200">
            Contact
          </h1>
          <p class="mt-1 text-sm font-normal text-zinc-400">
            View all contact requests, consultation requests and questions.
          </p>
        </div>
      </nav>

      <div
        class="card flex flex-wrap gap-x-6 max-md:gap-y-3 mt-5 justify-between bg-white dark:bg-zinc-800 w-full p-3 border dark:border-zinc-700"
      >
        <div class="flex max-sm:flex-col gap-y-3 gap-x-2 max-md:w-full">
          <div class="w-full">
            <dropdown placement="left">
              <template v-slot:button>
                <button
                  class="flex justify-between w-full px-4 py-3 border bg-zinc-100 dark:bg-zinc-900 text-sm dark:border-zinc-700"
                >
                  <span class="text-zinc-500 dark:text-zinc-400">Filter</span>

                  <span class="ml-4 mt-1 text-zinc-500"
                    ><Icon icon="ant-design:caret-down-filled"
                  /></span>
                </button>
              </template>
              <template v-slot:content>
                <div class="uppercase text-xs">
                  <a
                    @click="() => filterChange(null)"
                    href="#"
                    class="block hover:bg-jestaBlue hover:text-white px-4 py-2"
                  >
                    All</a
                  >
                  <a
                    @click="() => filterChange('HasReadDec')"
                    href="#"
                    class="block hover:bg-jestaBlue hover:text-white px-4 py-2"
                  >
                    Readed</a
                  >
                  <a
                    @click="() => filterChange('HasRead')"
                    href="#"
                    class="block hover:bg-jestaBlue hover:text-white px-4 py-2"
                  >
                    Unreaded</a
                  >
                  <a
                    @click="() => filterChange('CreatedDateDec')"
                    href="#"
                    class="block hover:bg-primary hover:text-white px-4 py-2"
                  >
                    Newest</a
                  >
                  <a
                    @click="() => filterChange('CreatedDate')"
                    href="#"
                    class="block hover:bg-primary hover:text-white px-4 py-2"
                  >
                    Oldest</a
                  >
                </div>
              </template>
            </dropdown>
          </div>
        </div>
        <div
          class="input-box border bg-zinc-100 dark:bg-zinc-900 dark:border-zinc-700 w-fit max-md:w-full box-border flex focus-within:bg-zinc-100 dark:focus-within:bg-zinc-700"
        >
          <div class="text-3xl p-2 text-zinc-400">
            <Icon icon="ei:search" />
          </div>

          <input
            v-model="searchContent"
            type="text"
            placeholder="Search..."
            class="p-3 w-full bg-zinc-100 dark:bg-zinc-900 dark:text-zinc-400 outline-none focus:bg-zinc-100 dark:focus:bg-zinc-700"
          />
        </div>
      </div>

      <div
        class="flex flex-col gap-5 mt-6"
      >
        <ContactBox
          v-for="(item, index) in contact"
          :key="item.id"
          :id="item.id"
          :firstName="item.firstName"
          :lastName="item.lastName"
          :email="item.email"
          :phoneNumber="item.phoneNumber"
          :question="item.question"
          :createdDate="item.createdDate"
          :hasRead="item.hasRead"
        ></ContactBox>
      </div>

      <div
        v-show="contact.length === 0 && !isLoading"
        class="w-full h-full flex justify-center items-center mt-20"
      >
        <span>Contact list is empty</span>
      </div>

      <div
        class="flex justify-center items-center w-full h-full p-16 pt-[140px]"
        v-show="isLoading"
      >
        <LoadingSpinner class="text-logicaTertiary dark:text-logicaSecondary" />
      </div>

      <div
        class="w-full flex justify-center mt-10 max-sm:mt-8"
        v-if="pagination.hasNext && !isLoading"
      >
        <div
          class="w-full max-w-[350px] max-sm:max-w-[250px] flex flex-col justi items-center gap-3"
        >
          <hr
            class="w-full h-1 max-sm:h-[2px] bg-jestaBrown mt-5 border-none"
          />
          <button
            @click="showMore"
            type="button"
            class="flex flex-col justify-center items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              class="fill-jestaBrown w-16 max-sm:w-10 h-16 max-sm:h-10"
              viewBox="0 0 256 256"
              xml:space="preserve"
            >
              <defs></defs>
              <g
                style="
                  stroke: none;
                  stroke-width: 0;
                  stroke-dasharray: none;
                  stroke-linecap: butt;
                  stroke-linejoin: miter;
                  stroke-miterlimit: 10;
                  fill: none;
                  fill-rule: nonzero;
                  opacity: 1;
                "
                transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
              >
                <path
                  d="M 90 23.25 c 0 -0.384 -0.146 -0.768 -0.439 -1.061 c -0.586 -0.586 -1.535 -0.586 -2.121 0 L 45 64.629 L 2.561 22.189 c -0.585 -0.586 -1.536 -0.586 -2.121 0 c -0.586 0.586 -0.586 1.535 0 2.121 l 43.5 43.5 c 0.585 0.586 1.536 0.586 2.121 0 l 43.5 -43.5 C 89.854 24.018 90 23.634 90 23.25 z"
                  style="
                    stroke: none;
                    stroke-width: 1;
                    stroke-dasharray: none;
                    stroke-linecap: butt;
                    stroke-linejoin: miter;
                    stroke-miterlimit: 10;
                    fill: #af8759;
                    fill-rule: nonzero;
                    opacity: 1;
                  "
                  transform=" matrix(1 0 0 1 0 0) "
                  stroke-linecap="round"
                />
              </g>
            </svg>

            <span
              class="uppercase text-jestaBrown font-semibold text-base max-sm:text-sm tracking-[0px]"
              >Show More</span
            >
          </button>
        </div>
      </div>
    </div>

    <ContactModal @readChange="readChange($event)"></ContactModal>
  </div>
</template>

<script>
import Dropdown from "../components/Dropdown.vue";
import ContactBox from "../components/Contact/ContactBox.vue";
import ContactModal from "@/components/Contact/ContactModal.vue";
import { Icon } from "@iconify/vue";
import axios from "axios";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  components: {
    Dropdown,
    ContactBox,
    ContactModal,
    Icon,
    LoadingSpinner,
  },
  data() {
    return {
      contact: [{}],
      isLoading: true,
      searchContent: null,
      filter: null,
      pagination: {},
    };
  },
  async mounted() {
    await this.getAllContacts();
  },
  methods: {
    async getAllContacts(filter = null, searchValue = null, pageNumber) {
      if (!pageNumber) {
        this.isLoading = true;
        this.contact = [];
      }

      let url = `Contact/GetAll?some=some&PageSize=12`;

      if (filter) {
        url += `&OrderBy=${filter}`;
      }

      if (searchValue) {
        url += `&SearchValue=${searchValue}`;
      }

      if (pageNumber) {
        url += `&PageNumber=${pageNumber}`;
      }

      await axios
        .get(url, { isNeedJwtAuth: true })
        .then((response) => {
          if (pageNumber) {
            this.contact = this.contact.concat(response.data.value);
          } else {
            this.contact = response.data.value;
          }

          this.pagination = response.data.pagination;

          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    async filterChange(filter) {
      this.filter = filter;
      await this.getAllContacts(filter, null);
    },
    async readChange(e) {
      await axios
        .put(`Contact/ToggleIsReadContactUs/${e.id}`, null, {
          isNeedJwtAuth: true,
        })
        .then(async (response) => {
          await this.getAllContacts();
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
        });
    },
    async showMore() {
      this.getAllContacts(
        this.filter,
        this.searchValue,
        ++this.pagination.currentPage
      );
    },
  },
  watch: {
    async searchContent(val) {
      this.searchContent = val;

      if (this.searchContent) {
        window.scrollTo(0, 0);

        await this.getAllContacts(null, this.searchContent);
      } else {
        window.scrollTo(0, 0);

        await this.getAllContacts();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
