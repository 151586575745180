<template>
  <file-pond
    name="test"
    ref="pictureUpload"
    class-name="my-pond"
    v-bind:allow-multiple="allowMultiple"
    allow-file-type-validation="true"
    allowImageExifOrientation="true"
    accepted-file-types="image/jpeg, image/png"
    max-file-size="10MB"
    :files="myFiles"
    @updatefiles="updateFiles"
  />
</template>

<script>
// Import FilePond
import vueFilePond from "vue-filepond";

// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";

// Import styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginImageExifOrientation
);

export default {
  data: function () {
    return {
      myFiles: [],
    };
  },
  methods: {
    updateFiles(file) {
      this.myFiles = file;
      this.$emit("updatePendingFiles", this.$refs.pictureUpload.getFiles());
      // console.log(this.pictureUpload);
    },
  },
  components: {
    FilePond,
  },
  computed: {
    refPound: function () {
      return this.$refs.pictureUpload;
    },
  },
  props: {
    allowMultiple: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
html {
  .filepond--root {
    margin-bottom: 0;
  }
  .filepond--credits {
    display: none;
  }

  .filepond--drop-label {
    color: rgb(161 161 170);
  }
  .filepond--label-action {
    text-decoration-color: rgb(113 113 120);
    color: rgb(113 113 120);
    font-weight: bold;
  }
  /* bordered drop area */
  .filepond--panel-root {
    background-color: rgb(244 244 245);
    border: 2px solid ;
    border-color: rgb(228 228 231);
    border-radius: 0;
  }

  @media screen and (max-width: 550px) {
    .filepond--drop-label label {
      font-size: 80%;
    }

    .filepond--file {
      font-size: 80% !important;
    }
  }

  &.dark {
    .filepond--drop-label {
      color: rgb(113 113 120);
    }
    .filepond--label-action {
      text-decoration-color: rgb(161 161 170);
      color: rgb(161 161 170);
    }
    /* bordered drop area */
    .filepond--panel-root {
      background-color: rgb(24 24 27);
      border-color: rgb(63 63 70);
    }
  }
}
</style>
