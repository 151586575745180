<template>
  <div class="container lg:max-w-7xl w-full h-screen flex items-center justify-center text-center px-6">
    <div class="space-y-8">
      <h1 class="md:text-9xl text-8xl text-jestaDarkBlue font-bold">404</h1>
      <h2 class="md:text-4xl text-3xl text-jestaDarkBlue">
        This Page Not Found
      </h2>
      <p class="max-w-2xl mx-auto text-jestaDarkGray md:text-base text-sm">
        Sorry, the page you are looking for
        {{ this.$route.params.pathMatch[0] }} was not found!
      </p>

      <div class="flex justify-center">
        <button
          type="button"
          @click="directTo('/')"
          class="max-w-[200px] max-sm:max-w-[170px] overflow-hidden w-full whitespace-nowrap inline-flex relative justify-center items-center border border-jestaBrown text-jestaBrown py-4 max-sm:py-3 px-5 uppercase text-base max-sm:text-xs  max-sm:font-bold tracking-[1px] after:content-[''] after:absolute after:w-full after:h-full  after:top-0 after:left-0 after:bg-jestaBrown after:translate-y-24 hover:after:translate-y-0 after:transition-all after:duration-300 transition-all duration-300 hover:text-white"
        >
          <span class="z-10">Return To Home</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    directTo(links) {
      window.location.href = links;
    },
  },
};
</script>

