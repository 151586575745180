<template>
  <div class="w-full h-full flex items-center justify-center">
    <div class="space-y-3 max-w-xs max-sm:max-w-[250px] p-5">
      <div class="form-head cursor-pointer" @click="$router.push('/')">
        <img
          src="../assets/logo/jestagroup-dark-logo.png"
          alt="logo"
          class="w-full hidden dark:block"
        />
        <img
          src="../assets/logo/jestagroup-light-logo.png"
          alt="logo"
          class="w-full block dark:hidden"
        />
      </div>
    </div>
  </div>
</template>
