import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import { createPinia } from "pinia";
import "./assets/tailwind.css";
import "./assets/animate.css";
import "./assets/sass/css/dashboard.css";
import vClickOutside from "click-outside-vue3";

// import plugin
import axios from "axios";
import settings from '@/settings/appSettings';

import {
  refreshTokenInterceptor,
  initialRefreshTokenInterceptor,
  jwtRequestInterceptor,
  initialLogOutMethod
} from "./lib/axiosJwtInterceptors";

import { useUserSignData } from "@/store/userSignData";
import { toastStore } from '@/store/toastStore.js';


initialRefreshTokenInterceptor({
  refreshTokenUrl: `${settings.baseUrl}/api/v1/Users/RefreshToken`,
});

axios.defaults.baseURL = `${settings.baseUrl}/api/v1/`;

axios.interceptors.request.use(jwtRequestInterceptor, undefined);

axios.interceptors.response.use(
  refreshTokenInterceptor(axios),
  refreshTokenInterceptor(axios)
);

const app = createApp(App);
app.use(router);
app.use(toastStore);
app.use(createPinia());


initialLogOutMethod(useUserSignData().logout)

app.use(VueApexCharts);
app.use(vClickOutside);
app.mount("#app");
