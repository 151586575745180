let isRefreshing = false;

let failedQueue = [];

let refreshTokenUrl = "";

let logoutMethod;

let localStorageObjectName = 'userSignData';

export const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

export const initialRefreshTokenInterceptor = (initialObject) => {
    refreshTokenUrl = initialObject?.refreshTokenUrl;
};

export const initialLogOutMethod = (inputLogoutMethod) => {
    logoutMethod = inputLogoutMethod;
};

export const initialLocalStorageObjectName = (name) => {
    localStorageObjectName = name;
};

export const refreshTokenInterceptor = (axiosInstance) => (axiosValue) => {
    const _axios = axiosInstance;

    const originalResponseConfig = axiosValue.config;
    console.log("originalResponseConfig", originalResponseConfig);

    if (axiosValue?.config?.isNeedJwtAuth === false) {
        if (axiosValue?.status < 299 && axiosValue?.status >= 200) {
            return Promise.resolve(axiosValue);
        } else {
            return Promise.reject(axiosValue);
        }
    }

    if (isRefreshing) {
        console.log("is refreshing...");

        return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
        })
            .then((token) => {
                console.log("is refreshing promise resolved");

                console.log("token for is refreshing promise: ", token);

                originalResponseConfig.headers["Authorization"] = "Bearer " + token;

                return _axios.request(originalResponseConfig);
            })
            .catch((err) => {
                console.log("is refreshing promise rejected");

                console.log("error in is refreshing promise: ", err);

                return Promise.reject(err);
            });
    }

    if (axiosValue?.status < 299 && axiosValue?.status >= 200) {
        return Promise.resolve(axiosValue);
    }

    if (
        (axiosValue?.response?.status === 401 && !axiosValue?.config?._retry) ||
        originalResponseConfig?.isNeedToRefreshToken
    ) {
        const refreshToken = JSON.parse(
            localStorage.getItem(localStorageObjectName)
        ).refreshToken;

        console.log(
            "refresh token for refresh:",
            refreshToken,
            "originalResponseConfig: ",
            originalResponseConfig
        );

        if (refreshToken) {
            console.log("refreshing...");

            originalResponseConfig._retry = true;

            isRefreshing = true;

            return new Promise((resolve, reject) => {
                fetch(`${refreshTokenUrl}/${refreshToken}`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: null,
                })
                    .then((res) => {
                        if (!res.ok) {
                            let logOutError = {
                                isNeedToLogOut: true,
                            };

                            console.log("logout");

                            processQueue(logOutError, null);

                            if (logoutMethod) {
                                logoutMethod();
                            }

                            return reject(logOutError);
                        }

                        return res.json();
                    })
                    .then((json) => {
                        if (json) {
                            let newToken = json?.value?.token;

                            let newRefreshToken = json?.value?.refreshToken;

                            console.log("new token", newToken);

                            const prevUserSignData = localStorage.getItem(localStorageObjectName);
                            localStorage.setItem(
                                localStorageObjectName,
                                JSON.stringify({
                                    ...JSON.parse(prevUserSignData),
                                    token: newToken,
                                    refreshToken: newRefreshToken,
                                })
                            );

                            _axios.defaults.headers.common["Authorization"] =
                                "Bearer " + newToken;

                            originalResponseConfig.headers["Authorization"] = "Bearer " + newToken;

                            processQueue(null, newToken);

                            resolve(_axios(originalResponseConfig));
                        }
                    })
                    .then(() => {
                        isRefreshing = false;

                        console.log("refresh status end");
                    });
            });
        }
    }

    return Promise.reject(axiosValue);
};

export const jwtRequestInterceptor = (config) => {

    console.log(config)

    if (config?.isNeedJwtAuth) {
        const token = JSON.parse(localStorage.getItem(localStorageObjectName))?.token;

        if (token && !config.url?.toLowerCase().includes("refreshtoken")) {
            console.log(
                "global interceptor request set token: ",
                token,
                "url: ",
                config.url
            );

            config.headers["Authorization"] = "Bearer " + token;
        }
    }

    return config;
};
