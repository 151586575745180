<template>
  <!-- Portfolio modal for get information of portfolio by id -->
  <Modal :has-footer="true" :modal-size="`lg`" :modal-id="`contactInfo`">
    <!-- Modal body -->
    <template v-slot:modalBody>
      <div>
        <div class="space-y-1 mb-5">
          <div
            class="flex items-center gap-2 font-medium text-zinc-700 dark:text-zinc-300 text-lg max-sm:text-base"
          >
            <h6 class="font-semibold text-jestaBrown">Name:</h6>
            <p class="space-x-1 text-base max-sm:text-sm">
              <span>{{ this.$store.state.contactModal.firstName }}</span
              ><span>{{ this.$store.state.contactModal.lastName }}</span>
            </p>
          </div>
          <div
            class="flex items-center gap-2 font-medium text-zinc-700 dark:text-zinc-300 text-lg max-sm:text-base"
          >
            <h6 class="font-semibold text-jestaBrown">Email:</h6>
            <p class="space-x-1 text-base max-sm:text-sm">
              {{ this.$store.state.contactModal.email }}
            </p>
          </div>
          <div
            class="flex items-center gap-2 font-medium text-zinc-700 dark:text-zinc-300 text-lg max-sm:text-base"
          >
            <h6 class="font-semibold text-jestaBrown">Phone number:</h6>
            <p class="space-x-1 text-base max-sm:text-sm">
              {{ this.$store.state.contactModal.phoneNumber }}
            </p>
          </div>

          <div
            class="flex items-center gap-2 font-medium text-zinc-700 dark:text-zinc-300 text-lg max-sm:text-base"
          >
            <h6 class="font-semibold text-jestaBrown">Sent at:</h6>
            <p class="space-x-1 text-base max-sm:text-sm">
              {{ this.$store.state.contactModal.createdDate }}
            </p>
          </div>
        </div>

        <p
          class="font-normal text-zinc-700 dark:text-zinc-300 text-lg max-sm:text-base"
        >
          {{ this.$store.state.contactModal.question }}
        </p>
      </div>
    </template>
    <template v-slot:modalFooter>
      <button
        @click="readContact"
        type="button"
        data-te-modal-dismiss
        class="inline-flex items-center text-sm font-normal text-center text-white bg-jestaBrown px-4 py-2"
      >
        {{ this.$store.state.contactModal.hasRead ? "Unread" : "Read" }}
      </button>
    </template>
  </Modal>
</template>

<script>
import Modal from "../../components/Modal.vue";
export default {
  components: {
    Modal,
  },
  methods: {
    async readContact() {
      this.$emit("readChange", {
        id: this.$store.state.contactModal.id,
        hasRead: !this.$store.state.contactModal.hasRead,
      });
    },
  },
};
</script>
