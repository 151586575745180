import { createStore } from "vuex";
import { Toast } from "tw-elements";

export const toastStore = createStore({
  state: {
    toastOptions: {
      title: "",
      body: "",
      type: "error",
      customClass: "",
    },
    contactModal: {
      id: null,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      question: "",
      hasRead: false,
    },
  },
  mutations: {
    showToast: function (state, options = null) {
      console.log(options);

      if (options?.title) {
        this.state.toastOptions.title = options.title;
      }

      if (options?.body) {
        this.state.toastOptions.body = options.body;
      }

      if (options?.type) {
        this.state.toastOptions.type = options.type;
      }

      if (options?.customClass) {
        this.state.toastOptions.customClass = options.customClass;
      }

      Toast.getInstance(document.getElementById(options.id)).show();
    },
    addContactInfo: function (state, contact) {
      state.contactModal = contact
    },
  },
});
