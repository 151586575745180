import { defineStore } from "pinia";
import axios from "axios";
import router from "../router";
import {toastStore} from '@/store/toastStore';

export const useUserSignData = defineStore("userSignData", {
  state: () => ({
    isAuthenticated: JSON.parse(localStorage.getItem("userSignData"))
      ? true
      : false,
    user: JSON.parse(localStorage.getItem("userSignData")),
  }),
  actions: {
    async login(username, password) {
      await axios
        .post(
          "Users/Login",
          {
            username: username,
            password: password,
          },
          {
            ignoreRequestInterceptor: true,
          }
        )
        .then((res) => {
          localStorage.setItem(
            "userSignData",
            JSON.stringify({ ...res.data.value })
          );

          this.isAuthenticated = true;
          this.user = { ...res.data.value };

          toastStore.commit("showToast", {
            id: "myToast",
            type: "success",
            title: "Welcome!",
            body: "Login successfully",
          });

          router.push("/");
        })
        .catch((err) => {
          console.log(err);
          if (!err.response.data?.isSuccess) {
            toastStore.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Login Error!",
              body: err.response.data?.messages[0],
            });
          }
        });
    },
    logout() {
      this.user = null;
      this.isAuthenticated = false;
      localStorage.removeItem("userSignData");
      router.push("/login");
    },
  },
});
