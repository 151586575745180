<script setup>
import { vOnClickOutside } from "@vueuse/components";
</script>

<template>
  <!-- sidebar -->
  <nav class="sidebar bg-white dark:bg-zinc-800" v-on-click-outside="closeMenuOnOutside">
    <!-- sidebar head -->
    <div class="sidebar-head flex items-start justify-between gap-5 p-4 pt-7">
      <router-link to="/" exact class="flex">
        <img
          src="@/assets/logo/jestagroup-dark-logo.png"
          alt="jesta logo"
          class="w-full max-w-[130px] hidden dark:block"
        />
        <img
          src="@/assets/logo/jestagroup-light-logo.png"
          alt="jesta logo"
          class="w-full max-w-[130px] block dark:hidden"
        />
      </router-link>
      <button class="lg:hidden block dark:text-zinc-400" @click="$emit('sidebarToggle')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          role="img"
          width="25px"
          height="25px"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 32 32"
        >
          <path
            fill="currentColor"
            d="M7.219 5.781L5.78 7.22L14.563 16L5.78 24.781l1.44 1.439L16 17.437l8.781 8.782l1.438-1.438L17.437 16l8.782-8.781L24.78 5.78L16 14.563z"
          />
        </svg>
      </button>
    </div>
    <!-- sidebar list -->
    <div
      class="sidebar-list overflow-y-auto h-[calc(100vh-(77.34px+1rem))] px-4 pb-4 mt-4 divide-y dark:divide-zinc-700"
    >
      <div class="pb-5">
        <p class="font-medium text-zinc-400 dark:text-zinc-400">Menu</p>
        <div class="wrap-item mt-4 dark:text-zinc-500">
          <div class="item">
            <router-link
              to="/"
              class="w-full flex text-left box-border p-3 text-zinc-800 dark:text-zinc-500 hover:bg-zinc-200 dark:hover:bg-zinc-700"
            >
              <span class="mr-3 text-2xl"><Icon icon="material-symbols:home" /></span>
              <span class="w-full"> Home </span>
            </router-link>
          </div>
          <div class="item mt-3">
            <menu-accordion>
              <template v-slot:icon>
                <Icon icon="dashicons:portfolio" />
              </template>
              <template v-slot:title> Portfolio </template>
              <template v-slot:content>
                <div>
                  <router-link
                    to="/portfolio/present-projects"
                    class="flex items-center gap-3 w-full text-left p-3 hover:bg-zinc-200 dark:hover:bg-zinc-700"
                  >
                    <span class="text-base"
                      ><Icon icon="material-symbols:arrow-right-alt-rounded"
                    /></span>
                    Present Projects
                  </router-link>

                  <router-link
                    to="/portfolio/past-projects"
                    class="flex items-center gap-3 w-full text-left p-3 hover:bg-zinc-200 dark:hover:bg-zinc-700"
                  >
                    <span class="text-base"
                      ><Icon icon="material-symbols:arrow-right-alt-rounded"
                    /></span>
                    Past Projects
                  </router-link>

                  <router-link
                    to="/portfolio/add"
                    class="flex items-center gap-3 w-full text-left p-3 hover:bg-zinc-200 dark:hover:bg-zinc-700"
                  >
                    <span class="text-base"
                      ><Icon icon="material-symbols:arrow-right-alt-rounded"
                    /></span>
                    New Project
                  </router-link>
                </div>
              </template>
            </menu-accordion>
          </div>
          <div class="item mt-3">
            <router-link
              to="/contact"
              class="w-full flex text-left box-border p-3 text-zinc-800 dark:text-zinc-500 hover:bg-zinc-200 dark:hover:bg-zinc-700"
            >
              <span class="mr-3 text-2xl"><Icon icon="ic:baseline-local-phone" /></span>
              <span class="w-full"> Contact </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { Icon } from "@iconify/vue";
import MenuAccordion from "./MenuAccordion.vue";
export default {
  components: {
    Icon,
    MenuAccordion,
  },
  methods: {
    closeMenuOnOutside() {
      this.$emit("closeMenuOnOutside");
    },
  },
};
</script>
