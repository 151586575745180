<template>
  <!-- Contact box -->
  <div
    :id="id"
    class="card w-full h-full p-3 bg-zinc-100 hover:bg-zinc-200 hover:border-zinc-300 border-zinc-200 border hover:dark:bg-zinc-700 dark:bg-zinc-800 hover:dark:border-zinc-600 dark:border-zinc-700 cursor-pointer"
    :class="
      hasRead
        ? 'bg-zinc-200/70 border-zinc-300/70 dark:bg-zinc-800/50 dark:border-zinc-700/50'
        : ''
    "
    data-te-target="#contactInfo"
    data-te-toggle="modal"
    @click="openContactModal"
  >
    <div
      class="hidden items-center sm:flex w-full font-medium text-zinc-700 dark:text-zinc-400 text-sm gap-5"
      :class="
        hasRead
          ? 'text-zinc-700/50 dark:text-zinc-400/40 marker:text-jestaBrown/50 dark:marker:text-jestaBrown/50'
          : ''
      "
    >
      <h5 class="shrink-0 line-clamp-1 w-full max-w-[150px]">
        <span>{{ firstName }} {{ lastName }}</span>
      </h5>

      <div class="flex gap-5 grow justify-between">
        <p class="line-clamp-1">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi
          debitis nulla, hic veritatis est sit reprehenderit reiciendis aut at
          temporibus tempora commodi vitae ullam. Alias sunt error nostrum
          distinctio cupiditate.
        </p>

        <h6 class="whitespace-nowrap shrink-0">{{ createdDate }} UTC</h6>
      </div>
    </div>

    <div
      class="flex flex-col items-start font-medium text-zinc-700 dark:text-zinc-400 space-y-1 sm:hidden text-sm gap-3"
      :class="
        hasRead
          ? 'text-zinc-700/50 dark:text-zinc-400/40 marker:text-jestaBrown/50 dark:marker:text-jestaBrown/50'
          : ''
      "
    >
      <div class="flex gap-3 justify-between w-full">
        <h5 class="space-x-1 line-clamp-1 w-full">
          <span>{{ firstName }} {{ lastName }}</span>
        </h5>

        <h6 class="whitespace-nowrap">{{ createdDate }} UTC</h6>
      </div>

      <p class="line-clamp-2">
        {{ question }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
    phoneNumber: {
      type: String,
      required: false,
    },
    email: {
      type: String,
      required: true,
    },
    createdDate: {
      type: String,
      required: true,
    },
    question: {
      type: String,
      required: true,
    },
    hasRead: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    openContactModal() {
      this.$store.commit("addContactInfo", {
        id: this.id,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phoneNumber: this.phoneNumber,
        question: this.question,
        hasRead: this.hasRead,
        createdDate: this.createdDate,
      });
    },
  },
};
</script>
